.lk {
  margin-bottom: 27px;

  & table {
    table-layout: fixed;
  }

  & td {
    padding: 0px !important;
    height: 40px;
  }
  & th {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    letter-spacing: 0;
    vertical-align: middle !important;
  }

  &__row--active, &__row--active:hover {
    background: rgb(217, 217, 217, 0.2) !important;
  }
}
