.lk {
  margin-bottom: 49px;

  & th {
    letter-spacing: 0;
    line-height: 1.5;
  }

  & th, & td {
    padding: 1px 7px !important;
  }
}
