@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/Ubuntu-Light.eot');
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('./assets/fonts/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Ubuntu-Light.woff2') format('woff2'),
    url('./assets/fonts/Ubuntu-Light.woff') format('woff'),
    url('./assets/fonts/Ubuntu-Light.ttf') format('truetype'),
    url('./assets/fonts/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/Ubuntu-Medium.eot');
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
    url('./assets/fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Ubuntu-Medium.woff2') format('woff2'),
    url('./assets/fonts/Ubuntu-Medium.woff') format('woff'),
    url('./assets/fonts/Ubuntu-Medium.ttf') format('truetype'),
    url('./assets/fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/Ubuntu-Regular.eot');
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
    url('./assets/fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Ubuntu-Regular.woff2') format('woff2'),
    url('./assets/fonts/Ubuntu-Regular.woff') format('woff'),
    url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype'),
    url('./assets/fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('./assets/fonts/OpenSans-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/OpenSans-SemiBold.woff') format('woff'),
    url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('./assets/fonts/OpenSans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/OpenSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/OpenSans-Regular.woff') format('woff'),
    url('./assets/fonts/OpenSans-Regular.ttf') format('truetype'),
    url('./assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('./assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/OpenSans-Bold.woff') format('woff'),
    url('./assets/fonts/OpenSans-Bold.ttf') format('truetype'),
    url('./assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('./assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/OpenSans-Light.woff2') format('woff2'),
    url('./assets/fonts/OpenSans-Light.woff') format('woff'),
    url('./assets/fonts/OpenSans-Light.ttf') format('truetype'),
    url('./assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('./assets/fonts/Ubuntu-Bold.eot');
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
    url('./assets/fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Ubuntu-Bold.woff2') format('woff2'),
    url('./assets/fonts/Ubuntu-Bold.woff') format('woff'),
    url('./assets/fonts/Ubuntu-Bold.ttf') format('truetype'),
    url('./assets/fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'OpenSans', 'Ubuntu', sans-serif;
  margin: 0;
}
