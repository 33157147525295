.wrapper {
    overflow-y: auto;
    &>div>div>div {
        font-size: 0;
    }
}
.table {
    width: 100%;
    &__row {
        display: table-row;
        // display: flex;
        transition-duration: 0.3s;
        &.tbody:hover {
            background: rgba(0, 181, 237, 0.11);
        }
    }
}

.table,
.th,
.td {
    padding: 6px 13px;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-collapse: collapse;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    height: 46px;
    color: #000000;
    word-break: keep-all;
    word-wrap:break-word;
}

.th {
    padding: 6px 0;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.14;
    color: #000000;
    letter-spacing: .06em;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0;
}
.td {
    text-align: center;
    vertical-align: middle;
}