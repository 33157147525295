.wrap {
  margin-top: 72px;

  & .title-api{
    margin-bottom: 10px;
  }
}

.content {
  padding: 30px 0 24px;

  & .font{
    font-family: 'OpenSans', 'Ubuntu';
  }

  & .link {
    font-family: 'OpenSans', 'Ubuntu';
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-decoration: underline;
    text-decoration-color: #00B5ED;
    text-underline-offset: 3px;
    position: relative;

    &:hover {
      color: #00b5ed;
    }

    &--active {
      color: #00b5ed !important;
    }
  }

  & .link-file {
    font-family: 'OpenSans', 'Ubuntu';
    color: #000000;
    letter-spacing: 0;

    &:hover {
      color: #00b5ed;
    }

    &--active {
      color: #00b5ed;
    }
  }
}

.lktable {
  margin-top: 12px;
}

.lktable_custom{
  &>*{
    padding: 12px 7px;
    &:first-child{
      min-width: calc(14% + 40px);
    }
  }
}