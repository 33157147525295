.copyright {
  font-family: 'OpenSans';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #54565A;
  text-decoration-color: #00B5ED;
  text-underline-offset: 5px;
  font-weight: 400;

  &:hover {
    color: #00B5ED;
  }
}
