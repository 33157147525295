.head {
  display: flex;
  align-items: center;
  width: 100%;
  &__logo {
    // height: 80px;
  }
  &__title {
    margin-left: 80px;
    color: #7db613;
    max-width: 624px;
  }
  & > *:last-child {
    flex: 1 1 auto;
  }
}

.head-confirm{
  align-items: normal;
}

.head-title-confirm{
  line-height: 18px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.copy {
  margin: 0 auto;
  margin-top: 44px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(84, 86, 90, 0.5);
  text-align: center;
}
