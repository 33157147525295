.header {
  margin-bottom: 82px;
  display: flex;
  align-items: center;

  &>*:first-child {
    margin-right: 64px;
  }

  &__title {
    color: #7db613;
  }
}

.button_wrap {
  display: flex;
  justify-content: center;

  button {
    min-width: 148px;
  }
}