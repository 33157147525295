.wrapper {
  padding: 5px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.error {
  color: red;
}

.warning {
  color: #000;
}
