.wrapper {
  margin-top: 72px;
}

.content {
  margin-top: 30px;

  &__title {
    margin-bottom: 12px;
  }

  &__block {
    &--second {
      margin-top: 33px;
    }
  }
}