.picker {
    position: relative;
    display: flex;
    width: 200px;
    height: 50px;
    &__range {
        position: absolute;
        left: 0;
        top: 0;
    }
    & input {
        padding: 6px 0!important;
        border: 1px solid #D9D9D9!important;
        line-height: 30px;
        font-family: 'OpenSans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px!important;
        line-height: 26px;
        text-align: center;
        width: 100px;
        color: #000000!important;
    }

}
.picker-block {
    position: relative;
    width: 100%;

}
.inputs {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    width: 263px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.ant-picker.ant-picker-range {
    position: absolute;
    opacity: 0;
    width: 1px;
}