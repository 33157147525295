.p {
  margin: 0;
  padding: 5px 0;
  font-family: 'OpenSans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
