.title {
  // min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 24px;
}
.error {
  margin-bottom: -10px;
  margin-top: 24px;
}
