.radio {
    position: relative;
    width: 16px;
    height: 16px;

    &::before {
        content: "";
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #D9D9D9;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        z-index: 2;
    }

    &:checked::after {
        background-color: #7DB613;
    }

    &:disabled {
        &::before {
            border-color: rgb(170, 170, 170);
        }

        &::after {
            background-color: rgb(170, 170, 170);
        }
    }
}

