.subscrube-menu {
  display: flex;

  &>*:not(:first-child) {
    margin-left: 15px;
  }
}

.table {
  margin-top: 20px;
}

.wrapper {
  margin-top: 72px;

  &__title {
    margin-bottom: 30px;
  }

  &__error {
    margin-top: 20px;
  }

  &__description {
    margin-bottom: 12px;
  }

  &__morebtn {
    margin-top: 12px;
    margin-bottom: 55px;
  }
}

.add-btn {
  &:focus {
    outline: 2px solid #000;
  }
}