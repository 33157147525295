.nav {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    &-link {
      // margin-right: 100px;
      text-align: right;
      letter-spacing: 0.05em;
      
      &:last-child {
        margin-right: 0;
        text-align: left;
      }

      a {
        color: #54565A;
      }
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
