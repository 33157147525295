.title {
    margin-bottom: 51px;
    color: #54565A;
    word-break: break-word;
    font-size: 24px;
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    padding: 65px 5px 72px;
}
