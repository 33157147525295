.note {
  padding: 50px 0 0 0;
  font-family: 'OpenSans';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  &:before {
    content: '';
    margin-bottom: 10px;
    display: block;
    width: 200px;
    height: 2px;
    background: #000;
  }
}
