.table{
    display: flex;
    // height: 100%;
    // margin-top: 80px;

    // &__subtitle {
    //     font-family: 'OpenSans-Regular';
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 22px;
    //     color: #000000;
    // }

    // &__email {
    //     text-decoration: underline;
    //     text-decoration-color: #00B5ED;
    //     text-underline-offset: 4px;
    // }

    &__wrap {
        display: flex;
        padding-left: 0;
        padding-right: 0;

        &>* {
            padding: 14px;
            font-style: normal;
            font-size: 14px;
            line-height: 1.14;
            color: #000000;
            display: inline-block;
            border: 1px solid #D9D9D9;
            font-family: 'OpenSans', 'Ubuntu';
            letter-spacing: 0;
            

            &:first-child {
                font-weight: 700;
                min-width: calc(18% + 50px);
            }

            &:last-child {
                font-weight: 400;
                flex: 1 1 auto;
                border-left: none;
            }
        }
        &+& > * {
            border-top: none;
        }
    }
    &__td {
        &:first-child {
            letter-spacing: 0;
        }
    }
}
