.footer {
  margin-top: 33px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-family: 'OpenSans';
  height: 70px;

  a {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  }
  &-copyright {
    a {
      font-size: 16px !important;
    }

    float:right;
  }
  &__wrapper {
    display: flex;
    width: 1024px !important;
    justify-content: space-between;
  }
  &__links {
    display: inline-flex;
    justify-content: space-between;
    & > * {
      font-family: 'OpenSans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      // line-height: 1;
      color: #54565A;
    }
    & > span:nth-child(1) {
      width: 66px;
    }
    & > span:nth-child(2) {
      width: 95px;
    }
    & > span:nth-child(3) {
      width: 161px;
    }
    & > span:nth-child(4) {
      width: 182px;
    }
    & > span:nth-child(5) {
      // width: 200px;
    }
  }
  &__outer-link {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #54565A;
    text-decoration-color: #00B5ED;
    text-underline-offset: 4px;
  }
  &>*:last-child {
    margin-left: auto;
    text-align: right;
  }
}

.item {
  color: #54565A;
  & > * {
    display: block;
    color: inherit;
  }
  & a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
}
.text--small {
  color: #8B8B8B;
  font-size: 13px;
}
