.header {
  margin-top: 48px;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-weight: 700;
  font-family: 'OpenSans', sans-serif;
  font-size: 16px;
  & > *:not(:last-child) {
    padding-top: 5px;
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }
}

.lk {
  position: absolute;
  margin-top: 24px;
  display: flex;
  align-items: center;
  & > *:last-child {
    margin-left: 13px;
  }
}
.item {
  letter-spacing: 0.03em;
}

.btn {
  display: flex;
  & > * {
    margin-right: 24px;
    &:first-child {
      margin-right: 44px;
    }
  }
  &-wrap {
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 200px;
  }
}
.menu { }

.tel {
  &-wrap {
    padding-left: 5px;
    & > * {
      letter-spacing: 0.06em;
    }
    & a {
      letter-spacing: 0.011em;
      line-height: 22px;
    }
  }
  text-decoration: none;
  font-family: inherit;
  color: inherit;
}

.login-btn {
  min-width: 139px !important;
}

.error {
  position: absolute;
  margin-top: 24px;
  display: flex;
  align-items: center;
  max-width: 100%;
  font-size: 16px;
  color: #ff4752;
  & > * {
    margin-right: 10px;
  }
}
