.btn {
  font-family: "OpenSans", sans-serif;
  padding: 9px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  height: 40px;
  color: white;
  cursor: pointer;
  min-width: 125px;
  padding: 5px 10px;
  line-height: 1.5;

  &:disabled {
    background-color: #d9d9d9;

    &:hover {
      background-color: #d9d9d9;
    }
  }

  &>svg {
    margin-right: 9px;
  }

  &>b {
    font-size: 20px;
  }

  &+& {
    margin-left: 15px;
  }

  &__small {
    height: 32px;
  }

  &__green {
    background: #7db613;

    &-slim {
      background: #7db613;
      font-size: 14px;
      font-weight: 700;

      &:hover {
        background-color: #659900;
      }
    }

    &-outline {
      background: #fff;
      border: 1px solid #7db613;
      color: #7db613;

      &:hover {
        border-color: #659900;
        color: #659900;
      }
    }

    &:hover {
      background-color: #659900;
    }
  }

  &__blue {
    background-color: #00b5ed;

    &:hover {
      background-color: #009cc9;
    }
  }

  &__gray {
    background-color: #d9d9d9;

    &:hover {
      background-color: #b1b1b1;
    }
  }

  &__lg {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  &__md {
    font-size: 14px;
    height: 34px;
  }

  &__sm {
    height: 32px;
    font-size: 14px;
  }
}

.tooltip {
  &__text {
    font-size: 12px;
    line-height: 1.2;
    color: #002033;
  }
}