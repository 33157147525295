.lbtn {
  padding: 6px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  min-width: 55px;
  left: calc(50% - 155px / 2);
  top: calc(50% - 40px / 2 - 63px);
  color: white;
  background: rgba(125, 182, 19, 1);
  width: 184px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0.05em;
  &.icon {
    padding-left: 9px;
  }
  &:hover {
    background-color: #659900;
  }

  cursor: pointer;

  &:disabled {
    background-color: #d9d9d9;

    &:hover {
      background-color: #d9d9d9;
    }
  }

  & > svg {
    margin-right: 5px;
  }

  & > b {
    font-size: 20px;
  }

  & + & {
    margin-left: 15px;
  }
}
