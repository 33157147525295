.link {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #54565a;
  text-decoration-color: #00b5ed;
  text-underline-offset: 4px;
  &:hover {
    color: #00b5ed;
  }
  &--active {
    color: #00b5ed;
  }
}
.header {
  font-weight: 700;
}

.footer {
  font-weight: 400;
  letter-spacing: 0.002em;
}

.text {
  color: #000;
}
