.wrap {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 46px;

    color: #000000;
    &__date {
        padding: 0 15px 0 13px;
        font-weight: 400;
    }

}

.title {
    margin-top: 72px;
    max-width: 650px;
}

.table {
    margin-top: 24px;
}
