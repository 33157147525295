.reg {
  margin-top: 72px;

  &__content {
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

  }
  &__list {
    margin: 0;
    padding: 5px 0 22px;
    list-style-type: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__buttons {
    &_wrapper {
      margin: 15px 0 33px;
      display: flex;
      border: 2px solid #7db613;
      border-radius: 10px;
      justify-content: space-between;
      padding: 16px 20px;
      align-items: center;
      flex-wrap: nowrap;
    }
    &_inner-span {
      display: inline-flex;
      width: 60%;
      justify-content: space-between;
    }
  }
  &__item {
    & + & {
      margin-top: 22px;
    }
  }
}

.load-title {
  color: #7db613;
  font-size: 20px;
  font-family: 'Ubuntu';
  font-weight: 700;
}

.align-center {
  display: inline-flex;
  width: 53%;
  align-items: center;
  letter-spacing: 0.035em;
  // margin-left: 21px;
}

.icon {
  margin-right: 5px;
}

.content {
  margin-top: 30px;
}

.table-title {
  font-weight: 700;
  margin: 0 0 15px 0;
}

.table-wrap {
  margin-bottom: 25px;
}

.table {

  & > *:first-child {
    min-width: calc(29% + 50px);
  }
}

.no-margin-top {
  margin-top: 0;
}
