.label {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  user-select: none;
  top: 3px;

  & input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }

  & input:disabled ~ .checkmark {
    border-color: #D9D9D9;
    color: #000000;
  }

  & input:focus ~ .checkmark {
    outline: 2px solid #000;
  }

  & input:checked:disabled ~ .checkmark {
    background: #F9F9F9;
  }

  &:hover input:not(:disabled) ~ .checkmark {
    border-color: #7DB613;
  }

  .checkmark {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9; 
    background-color: #fff;
    color: #7DB613;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &__lg {
      width: 24px;
      height: 24px;
    }
  }

  .text {
    margin-left: 13px;
  }
}