.wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    & > * + * {
        margin-left: 20px;
    }
    &-title{
        margin-top: 72px;
    }

    &-second {
        margin-top: 58px;
    }
}

.prod-pack {
    display: flex;
    justify-content: center;
    & > * + * {
        margin-left: 5px;
    }
} 

.table {
    margin-top: 30px;
}
.docs {
    & td {
        padding: 6px 7px;
    }
}
