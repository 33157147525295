.link {
  font-size: 16px;
  line-height: 22px;
  color: #54565a;
  // text-underline-offset: 4px;
  text-decoration: underline;
  text-decoration-color: #00B5ED;
  text-underline-offset: 3px;
  position: relative;
  // &::before {
  //   position: absolute;
  //   content: '';
  //   bottom: -1px;
  //   left: 0;
  //   width: 100%;
  //   height: 1px;
  //   display: block;
  //   width: 100%;
  //   background: #00b5ed;
  // }
  &:hover {
    color: #00b5ed;
  }
  &--active {
    color: #00b5ed!important;
  }
}
.header {
  font-weight: 700;
}

.footer {
  font-weight: 400;
}

.text {
  color: #000;
}
