.modal {
  padding: 35px 70px 14px;
  box-sizing: border-box;
  width: 788px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: start;
  position: relative;
  border: 4px solid #d9d9d9;
  border-radius: 10px;
  background: #ffffff;
  &__btn {
    position: absolute;
    right: 17px;
    top: 18px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: 11px;
      width: 26px;
      height: 2px;
      background: #d9d9d9;
      transform: rotate(45deg);
      border-radius: 10px;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: 11px;
      width: 26px;
      height: 2px;
      background: #d9d9d9;
      transform: rotate(-45deg);
      border-radius: 10px;
    }
  }
  // & .head {
  //   display: flex;
  //   align-items: center;
  //   width: 100%;
  //   min-height: 120px;
  //   &__logo {
  //     // height: 80px;
  //   }
  //   &__title {
  //     margin-left: 80px;
  //     color: #7db613;
  //     max-width: 624px;
  //   }
  //   & > *:last-child {
  //     flex: 1 1 auto;
  //   }
  // }
  // &__content {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: stretch;
  //   width: 100%;
  // }
}
.overlay {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(249, 247, 247, 0.367);
}

.confirm{
  width: 728px;
  padding: 21px 71px 14px 70px;
}

.subscribe{
  width: 762px;
  padding: 42px 70px 7px;
}



// .copy {
//   margin-top: 44px;
//   font-family: 'OpenSans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 22px;
//   color: rgba(84, 86, 90, 0.5);
//   text-align: center;
// }
