.wrapper {
  position: relative;
}

.modal__overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 600px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.logo {
  margin-right: 92px;
  & > * {
    height: 100%;
  }
}

.wrap {
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    min-width: 203px;
  }
}
.form {
  width: 100%;
}

.wrap__info {
  margin-top: 47px;
  padding-bottom: 35px;
}
.error {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #ff4752;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  &__item {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #54565a;
  }
}

.p {
  margin: 0;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #54565a;
  & ~ & {
    margin-top: 20px;
  }
}

.server {
  display: flex;
  align-items: center;
  position: absolute;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #ff4752;

  & > *:first-child {
    margin-right: 10px;
  }
}

// .copy {
//   margin-top: 44px;
//   font-family: 'OpenSans';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 22px;
//   color: rgba(84, 86, 90, 0.5);
//   text-align: center;
// }

.input {
  box-sizing: border-box;
  margin: 0;
  padding: 2px 21px;
  display: flex;
  height: 20px;
  width: 100%;
  outline: none;
  font-size: 16px;
  // color: rgba(84, 86, 90, 1);
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 46px;

  &:active,
  &:focus {
    border-color: #00b5ed;
  }

  & + & {
    margin-top: 43px;
  }

  &__label {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #ff4752;
    height: 75px;
    display: block;

    & + & {
      margin-top: 15px;
    }
  }

  &__label + & {
    margin-top: 10px;
  }

  &::placeholder {
    color: rgba(84, 86, 90, 0.5);
  }

  &__blue {
    border-color: #00b5ed;
  }

  &__error {
    border-color: #ff4752;
  }

  &__gray {
    border-color: #d9d9d9;
  }

  &__red {
    border-color: #ff4752;
  }
}
