.copy {
  margin: 0 auto;
  margin-top: 44px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(84, 86, 90, 0.5);
  text-align: center;
}
