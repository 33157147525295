.user {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 1024px;
  background-color: #fff;
}

.content {
  margin-top: 48px;
  margin-bottom: auto;
  min-height: 100%;
}

.footer {
  margin-bottom: 20px;
}

.copy {
  margin: 0 auto;
  margin-top: 44px;
  text-align: center;
}

.link{
  font-family: 'OpenSans';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #54565A;
  text-decoration-color: #00B5ED;
  text-underline-offset: 5px;
  font-weight: 400;
  &:hover {
    color: #00B5ED;
  }
}