.modal__overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 600px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.wrapper {
  //margin-top: 39px;
  color: #54565A;
  padding: 5px 0 0;
  box-sizing: border-box;
}

.title {
  color: #7db613;
  max-width: 624px;
  text-align: center;
}
.wrap {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 88px;
  right: 192px;

  button {
    padding: 0;
    min-width: 150px;
  }

  &-title {
    display: flex;
    align-items: center;
  }
}

.checkbox-group {
  display: flex;
  gap: 29px;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.desclamer{
  margin: 0;
}

.label {
  display: inline-block;
  font-size: 16px;
  line-height: 46.5px;
  font-weight: 400;
}