.input {
  box-sizing: border-box;
  margin: 0;
  padding: 2px 21px;
  display: flex;
  border-radius: 4px;
  border: 1px solid;
  height: 20px;
  width: 100%;
  outline: none;
  font-size: 16px;
  color: rgba(84, 86, 90, 1);
  height: 46px;
  & + & {
    margin-top: 43px;
  }
  &__label {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #FF4752;
   
  }
  &__label + & {
    margin-top: 10px;
  }
  &::placeholder {
    color: rgba(84, 86, 90, 0.5);
  }

  &__blue {
    border-color: #00B5ED;
  }

  &__error {
    border-color: #FF4752;
  }

  &__gray {
    border-color: #D9D9D9;
  }

  &__red {
    border-color: #FF4752;
  }
}
