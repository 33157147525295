.home {
    &__wrapper {
        background-color: #fff;
        margin-top: 72px;
    }
}
.title {
    margin-bottom: 30px;
    // font-family: inherit;
}
.section {
    &__wrap {
        display: flex;
    }
    &__logo {
        width: auto;
        height: 100%;
        & img {
            max-width: 100%;
        }
    }

    &__text {
        flex: 1 1 100%;
        max-width: 670px;
        &>h1 {
            margin: 0 -22px 22px 0;
            letter-spacing: .026em;
        }
    }
}

.list {
    margin: 5px 0 7px 10px;
    padding: 0 0 0 43px;
    list-style: disc;
    font-family: inherit;
    &__item {
        padding: 0 0 0 0px;
        font-family: 'OpenSans';
        letter-spacing: .002em;
    }
}