
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  &.left {
    width: auto;
    flex: 1 1 auto;
    // background-image: url('../../assets/img/left_bg.jpg');
        background-image: url('../../assets/img/bg.svg');
    background-position: right bottom;
  }
  &.right {
    width: auto;
    flex: 1 1 auto;
    // background-image: url('../../assets/img/rigth_bg.jpg');
        background-image: url('../../assets/img/bg.svg');
      background-position: left bottom;
  }
  background-repeat: no-repeat;

}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 1024px;
  background-color: #fff;
}
.content {
  height: 100%;
  flex: 1 1 auto;
}