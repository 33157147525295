.lk {
  &__subtitle {
    line-height: 46px;
  }

  &table *:first-child {
    min-width: calc(25% + 50px);
  }
  &table {
    &_custom {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.lktable_custom {
  & > *:first-child {
    min-width: calc(29% + 50px);
  }
  flex-basis: 50.5%;
  max-width: 50.5%;
}

.title-container {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  // height: 120px;

  & > *:last-child {
    & > button {
      padding-left: 0px;
      letter-spacing: normal;
    }
  }
  & > *:first-child {
    // padding-right: 30px;
    width: 625px;
  }
}
.title-btn {
  display: flex;
  align-items: center;

  & .lk__subtitle{
    margin: 0 15px 0 0;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    font-family: 'OpenSans', 'Ubuntu';
  }
}

.wrap {
  margin-top: 21px;

  &__title {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    & > h2 {
      letter-spacing: 0.03em;
    }
    & > *:last-child {
      margin-left: 30px;
    }
  }

  &__menu {
    margin-bottom: 24px;
    display: flex;

    & a {
      margin-right: 50px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    & a:first-child {
      flex-basis: 203px;
    }

    & a:nth-child(2) {
      flex-basis: 214px;
    }

    & a:nth-child(3) {
      flex-basis: 139px;
    }

    & a:last-child {
      margin-right: 0;
    }
  }
}

.wrap__btn-font{
  font-family: 'OpenSans', 'Ubuntu';
}

.wrap-date {
  display: flex;
  align-items: center;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 25px;

  color: #000000;

  &__date {
    font-weight: 400;
  }
  & > *:last-child {
    margin-left: 10px;
  }
}
