.header{
    font-family: 'OpenSans';
    font-weight: 700;
}

.footer {
    font-weight: 400;
}

.link {
    font-style: normal;
    font-size: 16px;
    color: #000;
    text-decoration-color: #00B5ED;
    text-underline-offset: 4px;
    &:hover {
        color: #00B5ED;
    }
    &--active {
        color: #00B5ED;
    }


}
