.h1 {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 1.08;
  color: #7db613;
  font-style: normal;
  line-height: 46px;
  margin-bottom: 30px;
}
.h2 {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 46px;
  color: #7db613;
}
.h3 {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #000000;
}
.h4 {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
